import { Code } from "@mui/icons-material";
import "../styles/projects.css";

import { projects } from "../data.js";
import { Paper } from "@mui/material";

const Projects = () => {
  return (
    <div className="projects">
      <div className="project-header">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Code fontSize="large" />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <h1 style={{ fontSize: "36px", marginTop: "0px" }}>
            Apps I've built
          </h1>
        </div>
        <p style={{ color: "#9ea3ad", marginTop: "-10px" }}>
          Here is a list of few applications that I've worked on.
        </p>
      </div>
      <div className="projects-grid">
        {projects.map((project) => (
          <a
            href={project.link}
            key={project.image}
            target="_blank"
            rel="noreferrer"
          >
            <Paper
              elevation={4}
              sx={{
                backgroundColor: "#212936",
                borderRadius: "15px",
              }}
            >
              <img
                style={{
                  width: "32rem",
                  height: "12rem",
                  borderTopLeftRadius: "15px",
                  borderTopRightRadius: "15px",
                  objectFit: "none",
                }}
                alt="project gif"
                src={project.image}
              />
              <div className="project-description">
                <h3
                  style={{
                    color: "#6ad09d",
                    marginTop: "-2px",
                    fontSize: "15px",
                  }}
                >
                  {project.subtitle}
                </h3>
                <h1
                  style={{
                    color: "#fff",
                    marginTop: "-10px",
                    fontSize: "18px",
                  }}
                >
                  {project.title}
                </h1>
                <p
                  style={{
                    color: "#9ea3ad",
                    marginTop: "-8px",
                    fontSize: "14px",
                    paddingLeft: "2px",
                    paddingRight: "2px",
                  }}
                >
                  {project.description}
                </p>
              </div>
            </Paper>
          </a>
        ))}
      </div>
    </div>
  );
};

export default Projects;
