import { Verified } from "@mui/icons-material";
import "../../styles/skills.css";

const Skill = ({ skill }) => {
  return (
    <div className="skill">
      <Verified sx={{ color: "#67cb99" }} />
      <h3 style={{ fontSize: "16px", color: "#fff" }}>{skill}</h3>
    </div>
  );
};

export default Skill;
