// todo: integrate with backend
export const projects = [
  {
    title: "Pharmedica",
    subtitle: "React, SpringBoot, MySQL, MongoDB",
    description:
      "An enterprise software for pharmacies for inventory control and sales.",
    image: "./pharmedica.gif",
    link: "https://pharmedica.amtrixtech.com.np/",
  },
  {
    title: "Amtrix Technology",
    subtitle: "Wordpress, React",
    description: "Website for company.",
    image: "./amtrix.gif",
    link: "https://amtrix-tech.com",
  },
  {
    title: "Android App for Salleri Khabar",
    subtitle: "Java, XML, Android",
    description: "Android app for this website.",
    image: "./salleri.gif",
    link: "https://www.sallerikhabar.com/",
  },
];

export const skills = [
  "Java",
  "Spring Boot",
  "Native Android Application",
  "JavaScript",
  "React JS",
  "React Native",
  "REST API",
  "Data Analysis",
  "Scientific Python",
  "R",
  "PowerBI",
  "SQL, NoSQL",
];
