import { People } from "@mui/icons-material";
import "../styles/contact.css";
import {
  Button,
  FormControl,
  Paper,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import { useRef } from "react";
import { MailService } from "../services/MailService";

const Contact = () => {
  const nameRef = useRef("");
  const emailRef = useRef("");
  const messageRef = useRef("");

  const mailService = new MailService();

  function submitForm() {
    const name = nameRef.current.value;
    const email = emailRef.current.value;
    const message = messageRef.current.value;

    const query = { name: name, email: email, message: message };
    console.log(query);

    mailService
      .sendMail(query)
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
        alert("Error!");
      });

    clear();
  }

  function clear() {
    nameRef.current.value = "";
    emailRef.current.value = "";
    messageRef.current.value = "";
  }

  return (
    <>
      <div className="contact">
        <div className="contact-header">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <People fontSize="large" />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h1 style={{ fontSize: "36px", marginTop: "0px" }}>Contact Me</h1>
          </div>
          <p style={{ color: "#9ea3ad", marginTop: "-10px" }}>
            I will be in touch with you as soon as possible!
          </p>
        </div>
      </div>

      <div className="contact-form-paper">
        <Paper elevation={3} style={{ borderRadius: "16px" }}>
          <div className="contact-form">
            <FormControl id="form" style={{ padding: "1rem" }}>
              <TextField
                id="name"
                label="Name"
                variant="standard"
                style={{ margin: "2rem 3rem 0rem 3rem" }}
                autoComplete="true"
                inputRef={nameRef}
              />
              <TextField
                id="email"
                label="Email"
                variant="standard"
                style={{ margin: "2rem 3rem 0rem 3rem" }}
                autoComplete="true"
                inputRef={emailRef}
              />
              <TextareaAutosize
                id="message"
                label="Message"
                placeholder="Write your message here..."
                minRows={4}
                style={{
                  margin: "3rem 3rem 3rem 3rem",
                  border: "none",
                  outline: "none",
                  resize: "none",
                  fontSize: "16px",
                }}
                ref={messageRef}
              />
              <Button
                id="submit"
                variant="contained"
                style={{
                  borderRadius: "15px",
                  minHeight: "40px",
                  fontSize: "16px",
                  margin: "0rem 3rem 1rem 3rem",
                }}
                onClick={submitForm}
              >
                Let's Talk
              </Button>
            </FormControl>
          </div>
        </Paper>
      </div>
    </>
  );
};

export default Contact;
