import { ArrowForward } from "@mui/icons-material";
import "../styles/navbar.css";

const Navbar = () => {
  return (
    <header className="navbar">
      <div className="navbar-items">
        <a href="#about" className="name-link">
          <strong>Prahlad Panthi</strong>
        </a>
        <div className="navbar-separator"></div>
        <div className="navbar-links">
          <nav>
            <a href="#projects">Past Work</a>
            <a href="#skills">Skills</a>
          </nav>
        </div>
        <div className="hire">
          <a href="#contact" className="hire-btn">
            Hire Me
            <ArrowForward fontSize="20px" />
          </a>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
