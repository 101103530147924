import { Memory } from "@mui/icons-material";
import "../styles/skills.css";
import Skill from "./mini-components/Skill";
import { skills } from "../data.js";

const Skills = () => {
  return (
    <>
      <div className="skills">
        <div className="skills-header">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Memory fontSize="large" />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h1 style={{ fontSize: "36px", marginTop: "0px" }}>
              Skills & Technologies
            </h1>
          </div>
          <p style={{ color: "#9ea3ad", marginTop: "-15px" }}>
            Here are a few technologies I enjoy to work with.
          </p>
        </div>
      </div>
      <div className="skills-grid">
        {skills.map((skill, index) => (
          <Skill key={index} skill={skill} />
        ))}
      </div>
    </>
  );
};

export default Skills;
