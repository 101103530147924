import "./App.css";
import About from "./components/About";
import Contact from "./components/Contact";
import Navbar from "./components/Navbar";
import Projects from "./components/Projects";
import Skills from "./components/Skills";

const App = () => {
  return (
    <div className="app">
      <Navbar />
      <div className="all-contents">
        <div className="content-section">
          <div className="content-about">
            <section id="about">
              <About />
            </section>
          </div>
          <div className="projects-section">
            <section id="projects">
              <Projects />
            </section>
          </div>
          <div className="skills-section">
            <section id="skills">
              <Skills />
            </section>
          </div>
          <div className="contact-section">
            <section id="contact">
              <Contact />
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
