import axios from "axios";

class MailService {
  sendMail(query) {
    return axios({
      method: "POST",
      url: "http://localhost:8000/sendmail.php",
      data: query,
    });
  }
}

export { MailService };
