import { GitHub, LinkedIn } from "@mui/icons-material";
import "../styles/about.css";
import { Button } from "@mui/material";
import Prahlad from "../static/prahlad.jpg";

const About = () => {
  return (
    <div className="about">
      <div className="left-section">
        <div className="header">
          <h1 style={{ fontSize: 32, fontWeight: "bold" }}>
            Hi 👋🏻, I'm Prahlad.
            <br />I love building amazing apps.
          </h1>
        </div>
        <div className="social-icons">
          <a
            href="https://www.github.com/prahlad1231/"
            target="_blank"
            rel="noreferrer"
          >
            <GitHub fontSize="large" />
          </a>
          <a
            href="https://www.linkedin.com/in/prahlad-panthi-a95607139"
            target="_blank"
            rel="noreferrer"
          >
            <LinkedIn fontSize="large" />
          </a>
        </div>
        <div>
          <p className="intro">
            I am a software engineer and a data analyst based on Canberra,
            Australia. I have a bachelors degree in Computer Science and
            Engineering, and masters in Data Science. Having over four years of
            experience working on building enterprise software solutions, I find
            myself preferring Spring Boot (Java) on backend and ReactJS on
            frontend. I love working across other technologies like React
            Native, Node, NextJS and native android application development. I
            enjoy creating useful insights through data visualization techniques
            from the data collected by the software I help to create. I lean
            towards tools like PowerBI and DAX, scientific python and R language
            to achieve my goals in data visualization.
          </p>
          <Button
            variant="contained"
            style={{
              marginRight: "1.2rem",
              minWidth: "170px",
              minHeight: "55px",
              fontSize: "16px",
              borderRadius: "15px",
            }}
            href="#contact"
          >
            Work With Me
          </Button>
          <Button
            variant="outlined"
            href="#projects"
            style={{
              minWidth: "250px",
              minHeight: "55px",
              fontSize: "16px",
              backgroundColor: "#212936",
              color: "#fff",
              borderRadius: "15px",
            }}
          >
            See My Past Work
          </Button>
        </div>
      </div>
      <div className="right-section">
        <div className="profile-picture">
          <img alt="Prahlad Panthi" src={Prahlad} height="750px" />
        </div>
      </div>
    </div>
  );
};

export default About;
